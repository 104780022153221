* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Inter Tight", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  color: #000;
  background: url('../public/media/background_gradient.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: #000;
  cursor: pointer;
}

button {
  background: none;
  border: 0;
  cursor: pointer;
}

h2, p {
  margin: 0;
}


img {
  display: block;
  width: 100%;
}

.header {
  background-color: #0000ff;
  color: #fff;
  padding: 1em 3em;
  font-size: .85rem;
  display: flex;
  justify-content: center;
}


.header_btn {
  border: .5px solid #0303be;
  border-radius: 8px;
  color: #fff;
  padding: 1em 1.3em;
  font-size: .85rem;
  margin-right: .8em;
}


.active {
  border: none;
  background-color: #7070f3;
}


.container {
  padding: 2.5em 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container h2 {
  background-color: #fff;
  padding: .3em .5em;
  margin-bottom: .2em;
  border-radius: 10px;
  text-align: center;
}

.logo_img {
  width: 50%;
  margin: 0 auto 2.5em;
}


.vimeo_container {
  margin: 2em 0;
}

.btn {
  background-color: #fa1818;
  padding: .7em;
  margin-bottom: 1em;
  border-radius: 8px;
  color: #fff;
  width: 100%;
  font-size: 1.1rem;
  font-weight: 700;
}

.join_group_btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn_img {
  width: 40px;
  display: inline;
}

.gif {
  margin: 2em 0;
}

.info_list p {
  margin-bottom: 1.5em;
  font-size: 1.2rem;
  font-weight: 500;
}

.try_btn {
  padding: 1em;
}

.price {
  font-size: .875rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .2em;
}

.price img {
  width: 25px;
  display: inline;
}

.price_btn {
  cursor: pointer;
}


.pop_up {
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pop_up_visible {
  transform: translateY(-100%);
}


.pop_up_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1em;
}


.pop_up_container p {
  text-align: center;
  font-size: 1.4rem;
  font-weight: 700;
  padding: .4em;
  margin-bottom: 1em;
  background-color: #fff;
  border-radius: 10px;
  line-height: 1.5;
}

.pop_up_container p:last-child {
  margin-bottom: 0;
  margin-top: 1em;
}

.footer {
  padding: 4em 0 2em;
  text-align: center;
  color: #a09d9d;
}

.terms_privacy {
  background-color: rgba(255, 255, 255, 0.8);
  overflow-y: scroll;
  display: block;
}

.terms_privacy_pop_up_container {
  width: 100%;
  color: #646363;
  text-align: left;
  font-size: .9rem;
  padding: 0 3em 3em;
}

.terms_privacy_pop_up_container h2 {
  margin: 1em 0;
}

.terms_privacy_pop_up_container h2,
.terms_privacy_pop_up_container h4 {
  width: 100%;
}

.terms_privacy_pop_up_container a {
  text-decoration: underline;
  color: #646363;
}

.close_icon {
  width: 35px;
}

.closeTermsPrivacy {
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .3em;
  width: 110px;
  padding: .6em;
  border-radius: 30px;
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
}

.closeTermsPrivacy img {
  display: inline-block;
}

.closeTermsPrivacy span {
  font-size: .9rem;
  color: #fff;
}


@media (min-width: 500px) {
  
  .container h2 {
      font-size: 2.2rem;
      padding: .1em .2em;
  }

  .vimeo_container {
    width: 500px;
  }

  .logo_img {
      width: 150px;
  }

 .join_group_btn {
      max-width: 500px;
  }

  .gif {
     max-width: 300px;
  }

  .try_btn {
      max-width: 400px;
      padding: 1.2em 0;
  }
  
  .join_group_pop_up_container, .price_pop_up_container {
      width: 400px;
  }

}

